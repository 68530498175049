.blue {
    color: #673ab7;
}

.secondary_headlines {
    text-align: center;
    font-size: 2.75rem !important;
    font-weight: 700 !important;
    color: #673ab7;
}

.indexContainer {
    padding-top: 30px;
}

.headline {
    text-align: left;
    color: #673ab7;
    font-weight: 700 !important;
    font-family: "Playfair Display", "Roboto", sans-serif !important;
}

.headline_attribution {
    text-align: left;
    font-size: 0.8rem !important;
    color: rgb(118, 118, 118);
}

.call_to_action {
    text-align: center;
}


.conflictContainer {
    padding: 20px;
    padding-right: 50px;
    background-size: 100% 100%;
    background-image: url('/imgs/home/background.png');
}

.quote_section {
    text-align: center;
}

.team {
    width: 100%;
    /*max-width: 550px;*/
    overflow: hidden;
    display: block;
    margin-left: auto;
}

.actionButton {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    color: white !important;
}

.customCard {
    text-align: center;
    height: 100%;
}

.customCardHeader {
    font-size: 3.5rem !important;
    font-weight: bold !important;
    color: #fe6b8b;
}

.customCardMedia {
    text-align: center;
}

.customCardMedia img {
    height: 300px;
    width: auto;
    margin: auto;
}

.reason_section {
    margin-top: 80px !important;
}

.quote {
    text-align: right;
    font-size: 2.3em;
    font-style: italic;
    font-family: "Playfair Display", "Roboto", sans-serif !important;
}

.winning_team {
    width: 100%;
    overflow: hidden;
}

.winning_section {
    width: 100%;
    margin-top: 70px !important;
    padding: 10px;
    background-position: 50% 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('/imgs/home/bubbles.png');
}

.supporterLogo {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.team_section {
    margin-top: 70px !important;
}

.team_container {
    text-align: left;
}

.team_name {
    color: #673ab7;
    font-size: 1.5rem !important;
}

.team_profile_image {
    max-width: 300px;
    height: auto;
    width: 100%;
}

.reference_profile_image {
    max-width: 200px;
    height: auto;
    width: 100%;
}

.reference_name {
    text-decoration: none !important;
}

.opinion {
    text-align: center;
    font-size: 1.4rem;
    font-family: "Playfair Display", "Roboto", sans-serif !important;
}

.opinion_author {
    text-decoration: none;
}

.demo_section {
    text-align: left !important;
}

.bubble {
    margin: 10px auto;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    position: relative;
}

.bubble_left:before {
    background-color: #FFFFFF;
    content: "\00a0";
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    right: -7.5px;
    bottom: 20px;
    transform: rotate(47deg) skew(5deg);
    -moz-transform: rotate(47deg) skew(5deg);
    -ms-transform: rotate(47deg) skew(5deg);
    -o-transform: rotate(47deg) skew(5deg);
    -webkit-transform: rotate(47deg) skew(5deg);
    box-shadow: 1px -1px 3px 0 rgba(0, 0, 0, .12);
}

.bubble_right:before {
    background-color: #FFFFFF;
    content: "\00a0";
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    left: -7.5px;
    bottom: 20px;
    transform: rotate(47deg) skew(5deg);
    -moz-transform: rotate(47deg) skew(5deg);
    -ms-transform: rotate(47deg) skew(5deg);
    -o-transform: rotate(47deg) skew(5deg);
    -webkit-transform: rotate(47deg) skew(5deg);
    box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, .12);
}

.press_section {
    margin-top: 70px !important;
}

.press_logo {
    width: 100%;
    height: auto;
}